
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from 'react';
import ErrorPage from 'next/error';
import Bugsnag from '../lib/bugsnag';

export declare type IErrorProps = {
  error: globalThis.Error;
  info: React.ErrorInfo;
  clearError: () => void;
  statusCode?: number;
};

const __Page_Next_Translate__ = class Error extends React.Component<IErrorProps> {
  static async getInitialProps(ctx) {
    if (ctx.err) Bugsnag.notify(ctx.err);

    return ErrorPage.getInitialProps(ctx);
  }

  render() {
    return <ErrorPage statusCode={this.props.statusCode || 500} />;
  }
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  